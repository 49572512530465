.is-horizontal-center {
	justify-content: center;
}

.quote-image {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.black-white {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

.quote-container {
	margin-top: 20px;
}

.refrence-header {
	margin-bottom: 12px !important;
}
