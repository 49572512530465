.call-to-action {
   font-size: 1rem !important;
   padding: 12px 24px 12px 24px;
   height: 3.5em;
}

.hero-subtitle {
   margin-top: 20px !important;
}

div.list {
   counter-reset: list-number;
}

div.list div:before {
   counter-increment: list-number;
   content: counter(list-number);

   margin-right: 10px;
   margin-bottom: -5px;
   width: 35px;
   height: 35px;
   font-weight: 700;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   background-color: #fff;
   border-radius: 50%;
   color: #000;
}

p.list-text {
   padding-left: 45px;
   margin-bottom: 20px;
}

section.has-frontpage-green-background {
   background-color: #B5C191;
}

.button.frontpage-dark {
   background-color: #536952;
}