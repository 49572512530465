@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,700");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display&display=swap");

// f1c9c7

// Set your brand colors
$rose-dark: #b48484;
$pink: #f1c9c7;
$olive: #92ac90;
$olive-light: #b4c193;
$olive-dark: #5c755e;
$grey-darker: #595959;
$grey: #8c8c8c;
$grey-light: #f5f5f5;
$white-bis: #fafafa;

// Update Bulma's global variables
$family-sans-serif: "Ubuntu",
   sans-serif;
$family-secondary: "Playfair Display";
$primary: $olive;
$link: $rose-dark;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $grey-light;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Navbar settings
$navbar-height: 4rem;
$navbar-dropdown-border-top: 0px;
$navbar-dropdown-radius: 0px;

// Section
$section-padding: 5rem 1.5rem;

// Footer
$footer-background-color: $grey-darker;
$footer-padding: 2.5rem 1.5rem;

$title-family: $family-secondary;

$input-border-color: $grey;

@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/bulma-extensions/bulma-divider/dist/css/bulma-divider.sass";
@import "./site.scss";