.pricing-banner {
   background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url("../_images/hero-background.jpg");
   height: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   padding-top: 12rem;
   padding-bottom: 24rem;
}

.price-tag {
   font-size: 5rem !important;
   line-height: 1;
   margin-top: 0.5rem !important;
}

.price-total {
   margin-top: 1rem;
}

.price-has-background-darker-green {
   background-color: #536952;
}

.price-has-background-lighter-green {
   background-color: #B5C191;
}