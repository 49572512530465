.validation {
   width: 60px !important;
}

.contact-banner {
   background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url("../_images/hero-background.jpg");
   height: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   padding-top: 12rem;
   padding-bottom: 24rem;
}