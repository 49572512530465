@include desktop {
   .blog-content {
      margin: 4rem;
   }
}

.blog-title {
   margin-bottom: 0.75rem !important;
}

.blog-date {
   margin-bottom: 2rem !important;
}

.blog-h4 {
   margin-top: 2rem;
}

.blog-wrapper {
   flex-wrap: wrap !important;
}

.blog-pixaloop-wrapper {
   padding-left: 200px;
   padding-right: 200px;
}

.is-line-through {
   text-decoration: line-through;
}

.is-underline {
   text-decoration: underline;
}

.blog-subtitle {
   padding-top: 1rem;
   margin-bottom: 0.5rem !important;
}

.blog-banner {
   background-image: url("../_images/blog-banner-c.png");
   height: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   padding-top: 9rem;
   padding-bottom: 19rem;
}