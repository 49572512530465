.carousel {
   overflow: hidden;
}

.inner {
   white-space: nowrap;
   transition: transform 0.3s;
}

.carousel-inner {
   white-space: normal;
}

.carousel-item {
   display: inline-flex;
   align-items: center;
   justify-content: center;
}

.carousel-content {
   width: 100%;
   display: block;
}

.indicators {
   display: flex;
   justify-content: center;
}

.indicators>button {
   margin: 5px;
   height: 10px;
   width: 10px;
   background-color: #bbb;
   border-radius: 50%;
   border: 0px;
   display: inline-block;
}

.indicators>button.active {
   background-color: rgb(129, 129, 129);
}

.carousel-card {
   border-radius: 7%;
}