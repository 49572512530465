section {
   background: $grey-light;
}

section:nth-of-type(2n) {
   background: $white;
}

.content-section {
   padding-top: 5rem;
   padding-bottom: 5rem;
}

.is-rose {
   background-color: $rose-dark;
   color: #fff;
}

.has-text-rose {
   color: $rose-dark;
}

.has-background-rose {
   background-color: $rose-dark;
}

.is-pink {
   background-color: $pink;
   color: $grey-darker;
}

.has-text-pink {
   color: $pink;
}

.has-background-pink {
   background-color: $pink;
}

.is-green {
   background-color: $olive;
   color: #fff;
}

.has-text-green {
   color: $olive;
}

.has-background-green {
   background-color: $olive;
}

.is-green-light {
   background-color: $olive-light;
   color: #fff;
}

.has-text-green-light {
   color: $olive-light;
}

.has-background-green-light {
   background-color: $olive-light;
}

.is-green-dark {
   background-color: $olive-dark;
   color: #fff;
}

.has-text-green-dark {
   color: $olive-dark;
}

.has-background-green-dark {
   background-color: $olive-dark;
}

.hero-element {
   background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url("../_images/hero-background.jpg");
   height: 70%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
}

.four-o-four-error {
   font-size: 15rem;
   margin: 0;
   padding: 0;
}

.four-o-four-text {
   margin-bottom: 2rem;
}

.four-o-four-button {
   margin-bottom: 10rem;
}

.content-section {
   padding-bottom: 8rem;
}

@include touch {
   .navbar-item {
      &.has-dropdown {
         .navbar-dropdown {
            display: none;
         }

         &.is-active {
            .navbar-dropdown {
               display: block;
            }
         }
      }
   }
}

@import "../Menu/menu.scss";
@import "../Pages/References/reference.scss";
@import "../Pages/Competences/competency.scss";
@import "../Pages/Frontpage/frontpage.scss";
@import "../Pages/Pricing/pricing.scss";
@import "../Pages/Blog/blog.scss";
@import "../Pages/Contact/contact.scss";
@import "../Components/components.scss";
@import "../Pages/CustomerFlowEmail/customerFlow.scss";
@import "divider-thick.scss";