$divider-thick-background-color: #94AC8F !default;
$divider-thick-thickness: .17rem !default;
$divider-thick-font-size: $size-7 !default;
$divider-thick-color: $grey-light !default;

.is-divider-thick,
.is-divider-thick-vertical {
   display: block;
   position: relative;
   border-top: $divider-thick-thickness solid $divider-thick-background-color;
   height: $divider-thick-thickness;
   text-align: center;
   width: 20%;
   margin-left: auto;
   margin-right: auto;
   padding-top: 2rem;
   padding-bottom: 1rem;
}

.is-divider-thick[data-content]::after,
.is-divider-vertical-thick[data-content]::after {
   background: $white;
   color: $divider-thick-color;
   content: attr(data-content);
   display: inline-block;
   font-size: $divider-thick-font-size;
   padding: .4rem .8rem;
   -webkit-transform: translateY(-1.1rem);
   -ms-transform: translateY(-1.1rem);
   transform: translateY(-1.1rem);
   text-align: center;
}